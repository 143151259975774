import { API } from 'aws-amplify'
import { fetchUserDesignation } from '../utils/meta'
import { hierarchyDetails } from '../../assets/offlineData/hierarchyData'

export const getMRs = (searchString, pageNumber, perPage, selectedHierarchy) => {
  const loggedInUserDesignation = fetchUserDesignation()

  if (loggedInUserDesignation === 'ae') {
    return API.get('baseURL', '/ae/meter-readers', {
      queryStringParameters: {
        ...(searchString && {
          ...(isNaN(Number(searchString.trim()))
            ? { mrName: searchString.trim() }
            : { mrCode: searchString.trim() })
        }),
        page: pageNumber,
        perPage
      }
    })
  }
  if (selectedHierarchy === 'All') {
    return API.get('baseURL', `/${loggedInUserDesignation}/user`, {
      queryStringParameters: {
        ...(searchString && {
          ...(isNaN(Number(searchString.trim()))
            ? { mrName: searchString.trim() }
            : { mrCode: searchString.trim() })
        }),
        userType: 'MR',
        page: pageNumber,
        perPage
      }
    })
  }

  const selectedUserAreaCode = hierarchyDetails.find(
    (item) => item.designation === selectedHierarchy.userType.toLowerCase()
  ).location.areaCode
  return API.get('baseURL', `/${loggedInUserDesignation}/user`, {
    queryStringParameters: {
      ...(searchString && {
        ...(isNaN(Number(searchString.trim()))
          ? { mrName: searchString.trim() }
          : { mrCode: searchString.trim() })
      }),
      userType: selectedHierarchy.userType,
      secondLevelUserType: 'MR',
      userLocationCode: selectedHierarchy.location[selectedUserAreaCode],
      page: pageNumber,
      perPage
    }
  })
}

export const updateMR = (id, body) => {
  return API.patch('baseURL', `/ae/meter-readers/${id}`, {
    body
  })
}
