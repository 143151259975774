import React, { useContext, useEffect, useState } from 'react'
import { NotifierContext } from '../../context/NotifierContext'
import { getFeeders } from '../../services/api/feederService'
import { checkIfUserIsAE, fetchSectionAndDivisionCode } from '../../services/utils/meta'
import NoDataFound from '../../components/atoms/NoDataFound'
import { DateToDDMMYYYY } from '../../services/utils/date'
import { HierarchyContext } from '../../context/HierarchyContext'

export default function Listing () {
  const notifier = useContext(NotifierContext)

  const [feeders, setFeeders] = useState([])
  const [feederLoader, setFeederLoader] = useState(false)
  const [feederPageData, setFeederPageData] = useState({
    lastPage: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    limit: 20,
    skip: 0
  })

  const [sectionCode, setSectionCode] = useState('----')
  const [subDivisionCode, setSubDivisionCode] = useState('----')

  const { selectedHierarchy } = useContext(HierarchyContext)

  useEffect(() => {
    fetchFeeders()
  }, [selectedHierarchy])

  useEffect(() => {
    fetchMetaData()
  }, [])

  const fetchMetaData = async () => {
    try {
      const sectionCode = await fetchSectionAndDivisionCode('sectionCode')
      const subDivisionCode = await fetchSectionAndDivisionCode('subDivisionCode')
      setSectionCode(sectionCode)
      setSubDivisionCode(subDivisionCode)
    } catch (err) {
      notifier.notifyError(err)
    }
  }

  // Logic to toggle accordian i.e, open and close accordian and load feeders from api.
  const fetchFeeders = async (event, stationCode) => {
    try {
      setFeederLoader(true)
      const response = await getFeeders(1, selectedHierarchy)
      setFeeders(response.data.feederList)
      setFeederPageData(response.data.pageData)
    } catch (err) {
      notifier.notifyError(err)
    } finally {
      setFeederLoader(false)
    }
  }

  const handleFeederPagination = async () => {
    try {
      setFeederLoader(true)
      const response = await getFeeders(feederPageData.currentPage + 1, selectedHierarchy)
      setFeeders([...feeders, ...response.data.feederList])
      setFeederPageData(response.data.pageData)
    } catch (err) {
      notifier.notifyError(err)
    } finally {
      setFeederLoader(false)
    }
    // console.log(selectedStationDetails.stationCode)
  }

  const isUserAE = checkIfUserIsAE()
  return (
    <>
      <div className='feeder-management-content'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 col-md-6'>
              <h1 className='mb-0'>Feeder Management</h1>
            </div>
          </div>
          <div className={` ${isUserAE ? '' : 'mt-4'}`} />
          <div className={`row ${isUserAE ? 'd-block' : 'd-none'}`}>
            <div className='col-md-12 '>
              <hr className='horizontal-line' />
              <div className='code-section'>
                <span>
                  Section Code: <span className='font-medium'>{sectionCode}</span>
                </span>
                <hr className='vertical-line mx-4' />
                <span>
                  Sub Division Code: <span className='font-medium'>{subDivisionCode}</span>
                </span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div
                className='feeder-management-list list-view-box mt-2'
                style={{ paddingTop: 25 }}
              >
                {/* <div className="search-heading">
                  <div className="row">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                      <div className="search-space position-relative me-auto top-feeder-search">
                        <Select
                          name="form-field-name"
                          options={option}
                          onChange={logChange}
                          placeholder="Search by Feeder Name or MDM Code"
                          isSearchable
                        />
                        <img className="img-fluid fa" src={searchIcon} />
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="substation-list"> */}

                <div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th
                          className='pl-15 text-center'
                          scope='col'
                          style={{ borderBottom: '1px solid #dddddd' }}
                        >
                          #
                        </th>
                        <th
                          scope='col'
                          className='text-center'
                          style={{ borderBottom: '1px solid #dddddd' }}
                        >
                          Feeder Number
                        </th>
                        <th
                          scope='col'
                          className='text-center'
                          style={{ borderBottom: '1px solid #dddddd' }}
                        >
                          Feeder Name
                        </th>
                        <th
                          scope='col'
                          className='text-center'
                          style={{ borderBottom: '1px solid #dddddd' }}
                        >
                          Feeder MDM Code
                        </th>
                        <th
                          scope='col'
                          className='text-center'
                          style={{ borderBottom: '1px solid #dddddd' }}
                        >
                          Power station
                        </th>
                        <th
                          scope='col'
                          className='text-center'
                          style={{ borderBottom: '1px solid #dddddd' }}
                        >
                          Added Date
                        </th>

                      </tr>
                    </thead>
                    <tbody className='text-center'>
                      {feederLoader
                        ? (
                          <NoDataFound message='Loading...' />
                          )
                        : feeders.length === 0
                          ? (
                            <NoDataFound message='No Feeders found!' />
                            )
                          : (
                              feeders.map((feeder, index) => {
                                return (
                                  <tr key={feeder.id}>
                                    <td
                                      className='pl-15 text-center'
                                      scope='row'

                                    >
                                      {index + 1}
                                    </td>
                                    <td className='text-center'>{feeder.number ?? '----'}</td>
                                    <td className='text-center'>{feeder.name ?? '----'}</td>
                                    <td className='text-center'>{feeder.mdmCode ?? '----'}</td>
                                    <td className='text-center'>{feeder.powerStation.name ?? '----'}</td>
                                    <td className='text-center'>{DateToDDMMYYYY(feeder.createdAt)}</td>
                                  </tr>
                                )
                              })
                            )}

                    </tbody>
                  </table>
                </div>
                {(!feederLoader && !(feederPageData.currentPage === feederPageData.lastPage)) &&
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: 20,
                      textDecoration: 'underline'
                    }}
                  >
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleFeederPagination()}
                    >
                      Load More...
                    </div>
                  </div>}
                {/* </InfiniteScroll> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
