import React, { useState } from 'react'
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
// import Dashboard from './screens/Dashboard/Index'
import './assets/scss/index.scss'
import './assets/scss/style.scss'
import './assets/scss/xyg.scss'
import './assets/css/font-awesome.min.css'
import Login from './screens/Auth/Login'
import { awsConfig } from './aws-exports'
import Amplify from 'aws-amplify'
import NotifierProvider from './context/NotifierContext'
import { ToastProvider } from 'react-toast-notifications'
import { AuthContext } from './context/AuthContext'
import AuditMonthProvider from './context/AuditMonthContext'
import AuditResultProvider from './context/AuditResultContext'
import HierarchyProvider from './context/HierarchyContext'
import CheckInternetConnection from './components/atoms/CheckInternetConnection/CheckInternetConnection'
import EnvironmentStrip from './components/atoms/EnvironmentStrip'
import NoResource from './screens/FileUpload/Files/NoResource'
import ForgotPassword from './screens/Auth/ForgotPassword/ForgotPassword'
import DashboardWrapper from './navigators/DashboardWrapper'

Amplify.configure(awsConfig)

const App = (props) => {
  const [isLoggedIn, setLoggedIn] = useState(window.localStorage.getItem('refreshToken'))

  const signInSuccessHandler = () => {
    const refreshToken = window.localStorage.getItem('refreshToken')
    setLoggedIn(refreshToken !== null)
  }

  return (
  // <HashRouter>
    <Router>
      <CheckInternetConnection>
        <EnvironmentStrip>
          <AuthContext.Provider value={{ signInSuccessHandler }}>
            <HierarchyProvider>
              <ToastProvider
                placement='bottom-right'
                autoDismiss
              >
                <NotifierProvider>
                  <AuditMonthProvider>
                    <AuditResultProvider>
                      <Switch>
                        <Route exact path='/login'>
                          {isLoggedIn ? <Redirect to='/' /> : <Login signInSuccess={signInSuccessHandler} />}
                        </Route>
                        <Route path='/forgot-password/:username'>
                          {isLoggedIn ? <h1>Logout to use this screen</h1> : <ForgotPassword />}
                        </Route>
                        <Route path='/'>
                          {isLoggedIn
                            ? <DashboardWrapper signInSuccessHandler={signInSuccessHandler} />
                            : <Redirect to='/login' />}
                        </Route>
                        {/* <Redirect exact from='/' to='/login' /> */}
                        <Route component={NoResource} />
                      </Switch>
                    </AuditResultProvider>
                  </AuditMonthProvider>
                </NotifierProvider>
              </ToastProvider>
            </HierarchyProvider>
          </AuthContext.Provider>
        </EnvironmentStrip>
      </CheckInternetConnection>
    </Router>
  // </HashRouter>
  )
}

export default App
