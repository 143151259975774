import React from 'react'
import styles from './fileHistoryHeader.module.scss'
import { useHistory } from 'react-router-dom'
import backIcon from '../../../assets/img/back-arrow-circle.png'
import RefreshButton from '../../atoms/RefreshButton/RefreshButton'

const FileHistoryHeader = ({ title = 'File', onRefresh, loading }) => {
  const history = useHistory()

  return (
    <div className={styles.header}>
      <img src={backIcon} alt='Missing Icon' onClick={() => history.goBack()} />
      <div className={`ms-3 ${styles.heading}`}>{title} | File upload history
      </div>
      <RefreshButton onRefresh={onRefresh} loading={loading} />
    </div>
  )
}

export default FileHistoryHeader
