import React, { Fragment, useContext, useState, useEffect } from 'react'
import closeIcon from '../../../../assets/img/close-icon.png'
import gpsIcon from '../../../../assets/img/gpc-location.svg'
import Spinner from '../../../../components/atoms/Spinner'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import MultiSelect from 'react-multi-select-component'
import Select from 'react-select/async'
import {
  executionType,
  readingDay,
  tcCapacity as tcCapacityOptions,
  tcReadingRemarks
} from '../../../../assets/offlineData/TCOfflineData'
import { NotifierContext } from '../../../../context/NotifierContext'
import { DateToYYYYMM } from '../../../../services/utils/date'
import { addTC, searchFeeder } from '../../../../services/api/tcService'
import { getMRs } from '../../../../services/api/mrService'
import { addTCValidator } from '../../../../services/validators/tcValidator'
import { AuditMonthContext } from '../../../../context/AuditMonthContext'

const AddTC = (props) => {
  const notifier = useContext(NotifierContext)
  const { auditMonthYear } = useContext(AuditMonthContext)

  const [activeScreen, setActiveScreen] = useState('add_tc_form')
  const [isDisabled, setIsDisabled] = useState(false)
  const [MRs, setMRs] = useState([{ label: 'Loading...', value: 'Loading' }])
  const [feederNumber, setFeederNumber] = useState('') // For display purpose only

  const [state, setState] = useState({
    taggedMRs: [],
    readingMR: '',
    readingDay: '',
    tcName: '',
    tcNumber: '',
    tcSerialNumber: '',
    tcMake: '',
    tcCapacity: '',
    timsCode: '',
    dtlmsCode: '',
    dtrCode: '',
    gpsCoordinates: {
      latitude: '',
      longitude: ''
    },
    executionType: '',
    feederId: '',
    meterMake: '',
    meterSerialNumber: '',
    primaryCurrent: '',
    secondaryCurrent: '',
    meterConstant: '',
    initialReadingMonth: '',
    initialReading: '',
    remarks: '',
    tcReadingMRs: []
  })

  const [gpsLoader, setGpsLoader] = useState(false)

  useEffect(() => {
    fetchMRs()
  }, [])

  useEffect(() => {
    if (
      !state.secondaryCurrent ||
      !state.primaryCurrent ||
      state.primaryCurrent === 0 ||
      state.secondaryCurrent === 0
    ) {
      setState({ ...state, meterConstant: '' })
      return
    }
    setState({
      ...state,
      meterConstant: String(
        Math.round((state.primaryCurrent / state.secondaryCurrent) * 100) / 100
      )
    })
  }, [state.secondaryCurrent, state.primaryCurrent])

  const fetchMRs = async () => {
    try {
      const response = await getMRs('', 1, 100)
      setMRs(
        response.data.MRList.map((item) => {
          return {
            label: item.userInfo.mrCode,
            value: item.cognitoDetails.sub
          }
        })
      )
      // Select first MR by default.
      // setState({
      //   ...state, taggedMRs: [{
      //     label: response.data.MRList[0].userInfo.mrCode,
      //     value: response.data.MRList[0].cognitoDetails.sub
      //   }
      //   ]
      // })
    } catch (err) {
      notifier.notifyError(err)
    }
  }

  const setGPSCoordinates = () => {
    setGpsLoader(true)
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setState({
          ...state,
          gpsCoordinates: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          }
        })
        setGpsLoader(false)
      },
      function (error) {
        notifier.notifyError(`${error.message} - Grant Permission to capture TC location`)
        setGpsLoader(false)
      }
    )
  }

  const handleFeederSearch = async (input) => {
    try {
      if (input.length >= 1) {
        const response = await searchFeeder(input)
        return response.data.feederList.map((item) => {
          return { label: `${item.number}, ${item.name}`, value: item.id }
        })
      }
    } catch (err) {
      notifier.notifyError(err)
    }
  }

  const tcConfirmScreenHandler = (event) => {
    try {
      addTCValidator(state)
      setActiveScreen('confirm_tc_form')
    } catch (err) {
      notifier.notifyError(err)
    }
  }

  const tcFormShowHandler = (event) => {
    setActiveScreen('add_tc_form')
  }

  const onCloseButtonHandler = (event) => {
    props.onCloseButton()
  }

  const handleStateChange = (key, value) => {
    value?.target?.validity?.valid && setState({ ...state, [key]: value?.target?.value })
  }

  const handleAddTC = async () => {
    try {
      setIsDisabled(true)
      const data = addTCValidator(state, auditMonthYear)
      const response = await addTC(data)
      notifier.notifySuccess(response.message)
      setIsDisabled(false)
      props.onCloseButton()
      props.onAdd()
    } catch (err) {
      notifier.notifyError(err)
      setIsDisabled(false)
    }
  }

  const readingMRHandler = (e) => {
    const value = e.currentTarget.value
    setState({
      ...state,
      readingMR: value
    })
  }

  return (
    <>
      <div className='row add-tc-info'>
        <div className='col-md-12 mb-3 mt-3'>
          <div className='code-section'>
            <span>Energy Audit &nbsp;|</span>
            <span className='add-new-tc-text'>
              <a>&nbsp; Add New TC</a>
            </span>
          </div>
          <hr className='horizontal-line' />
        </div>
      </div>

      <div className='row add-tc-info'>
        <div className='col-md-6'>
          <div className='add-tc-title'>
            <h1 className='mb-0'>Add New TC</h1>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='close-mr'>
            <button
              onClick={onCloseButtonHandler}
              type='button'
              className='btn btn-primary'
            >
              <img src={closeIcon} />
              Close
            </button>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 mt-4'>
          {activeScreen === 'add_tc_form' && (
            <div className='transformer-detail-form p-3 mb-3'>
              <div className='forms-input'>
                <div className='row custom-row'>
                  <div className='col-md-12 mb-3 mt-2'>
                    <div className='x-former-detail'>
                      <h3 className='mb-2'>Transformer Details</h3>
                    </div>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>Select MR Code</label>
                    <MultiSelect
                      options={MRs}
                      value={state.taggedMRs}
                      onChange={(value) =>
                        setState({ ...state, taggedMRs: value, readingMR: value[0]?.value ?? '' })}
                      disableSearch='true'
                      placeholderText='Select MR Code'
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TC Reading MR</label>
                    <div className='reading-radio-btn'>
                      <div className='row'>
                        {state.taggedMRs.length === 0
                          ? (
                            <p>-----</p>
                            )
                          : (
                              state.taggedMRs.map((item) => (
                                <div className='col-12 col-md-6' key={item.value}>
                                  <div
                                    className='form-check mb-0 me-3'

                                  >
                                    <input
                                      checked={state.readingMR === item.value}
                                      onChange={readingMRHandler}
                                      type='radio'
                                      className='form-check-input'
                                      value={item.value}
                                      id='flexCheckDefault'
                                      style={{ width: 20, height: 20, marginRight: 10 }}
                                    />
                                    <label
                                      className='form-check-label'
                                      htmlFor='flexRadioDefault1'
                                    >
                                      {item.label}
                                    </label>
                                  </div>
                                </div>
                              ))
                            )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TC Number *</label>
                    <input
                      value={state.tcNumber}
                      onChange={(e) => {
                        handleStateChange('tcNumber', e)
                      }}
                      className='form-control'
                      maxLength={10}
                      type='text'
                      placeholder='TC Number'
                    />
                  </div>

                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TC Name *</label>
                    <input
                      value={state.tcName}
                      onChange={(e) => {
                        handleStateChange('tcName', e)
                      }}
                      className='form-control'
                      type='text'
                      maxLength={500}
                      placeholder='TC Name'
                    />
                  </div>

                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>Reading Day *</label>
                    <select
                      className='form-select'
                      aria-label='Reading Day'
                      onChange={(e) => handleStateChange('readingDay', e)}
                    >
                      <option value=''>Reading Day</option>
                      {readingDay.map((item) => (
                        <option
                          value={item}
                          key={`${item}`}
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TC Serial Number</label>
                    <input
                      value={state.tcSerialNumber}
                      onChange={(e) => {
                        handleStateChange('tcSerialNumber', e)
                      }}
                      className='form-control'
                      type='text'
                      maxLength={30}
                      placeholder='TC Serial Number'
                    />
                  </div>

                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TC Make</label>
                    <input
                      value={state.tcMake}
                      onChange={(e) => {
                        handleStateChange('tcMake', e)
                      }}
                      className='form-control'
                      type='text'
                      maxLength={30}
                      placeholder='TC Make'
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TC Capacity</label>
                    <select
                      value={state.tcCapacity}
                      onChange={(e) => {
                        handleStateChange('tcCapacity', e)
                      }}
                      className='form-select'
                      aria-label='TC Capacity'
                    >
                      <option value=''>TC Capacity</option>
                      {tcCapacityOptions.map((item) => (
                        <option
                          value={item.value}
                          key={item.value}
                        >
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TIMS Code</label>
                    <input
                      value={state.timsCode}
                      onChange={(e) => {
                        handleStateChange('timsCode', e)
                      }}
                      className='form-control'
                      type='text'
                      maxLength={30}
                      placeholder='Tims Code'
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>DTLMS</label>
                    <input
                      value={state.dtlmsCode}
                      onChange={(e) => {
                        handleStateChange('dtlmsCode', e)
                      }}
                      className='form-control'
                      type='text'
                      maxLength={30}
                      placeholder='DTLMS'
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>DTR</label>
                    <input
                      value={state.dtrCode}
                      onChange={(e) => {
                        handleStateChange('dtrCode', e)
                      }}
                      className='form-control'
                      type='text'
                      maxLength={30}
                      placeholder='DTR'
                    />
                  </div>
                  <div className='col-md-4 mb-4 position-relative'>
                    <label className='form-label font-regular-400'>GPS Location</label>
                    {gpsLoader
                      ? <div><Spinner /></div>
                      : (
                        <>
                          <input
                            className='form-control'
                            value={`${state.gpsCoordinates.latitude}, ${state.gpsCoordinates.longitude}`}
                            type='text'
                            disabled
                            style={{ background: 'white' }}
                            placeholder='GPS Location'
                          />
                          <img
                            className='img-fluid fa-compass'
                            src={gpsIcon}
                            onClick={setGPSCoordinates}
                            style={{ cursor: 'pointer' }}
                          />
                        </>
                        )}
                  </div>
                  <div className='col-md-4 mb-4 position-relative feeder-name-select'>
                    <label className='form-label font-regular-400'>Execution Type *</label>
                    <select
                      value={state.executionType}
                      onChange={(e) => {
                        handleStateChange('executionType', e)
                      }}
                      className='form-select'
                      aria-label='Execution Type'
                    >
                      <option value=''>Execution Type</option>
                      {executionType.map((item) => (
                        <option
                          value={item.value}
                          key={item.value}
                        >
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col-md-4 mb-4 position-relative'>
                    <label className='form-label font-regular-400'>Feeder Search *</label>
                    <div className='search-space position-relative me-auto top-feeder-search'>
                      <Select
                        loadOptions={handleFeederSearch}
                        onChange={(option) => {
                          option?.value && setState({ ...state, feederId: option.value })
                          setFeederNumber(option.label)
                        }}
                        placeholder='Search Feeder Number'
                        isSearchable
                        isClearable
                      />
                    </div>
                  </div>
                  <hr className='divider' />
                  {/* /////////////////////////////////////////////METER DETAILS////////////////////////////////// */}
                  <div className='col-md-12 mb-3 mt-2'>
                    <div className='x-former-detail'>
                      <h3 className='mb-2'>Meter Details</h3>
                    </div>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>Make</label>
                    <input
                      value={state.meterMake}
                      onChange={(e) => {
                        handleStateChange('meterMake', e)
                      }}
                      className='form-control'
                      type='text'
                      maxLength={30}
                      placeholder='Make'
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>Serial Number</label>
                    <input
                      value={state.meterSerialNumber}
                      onChange={(e) => {
                        handleStateChange('meterSerialNumber', e)
                      }}
                      className='form-control'
                      type='text'
                      maxLength={30}
                      placeholder='Serial Number'
                    />
                  </div>
                  <div className='col-md-4 mb-4' />
                  <div className='col-md-8 mb-4'>
                    <label className='form-label font-regular-400'>CT Ratio *</label>

                    <div className='row'>
                      <div className='col-12 col-md-6 mb-4'>
                        <input
                          value={state.primaryCurrent}
                          onChange={(e) => {
                            handleStateChange('primaryCurrent', e)
                          }}
                          className='form-control'
                          pattern='[0-9]*'
                          maxLength={10}
                          type='text'
                          placeholder='Primary Current'
                        />
                      </div>

                      <div className='col-12 col-md-6 mb-4'>
                        <input
                          value={state.secondaryCurrent}
                          onChange={(e) => {
                            handleStateChange('secondaryCurrent', e)
                          }}
                          className='form-control'
                          pattern='[0-9]*'
                          maxLength={10}
                          type='text'
                          placeholder='Secondary Current'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400' />
                    <p>Meter Constant - {state.meterConstant}</p>
                  </div>

                  <hr className='divider' />
                  {/* //////////////////////////INITIAL READING////////////////////////////////////// */}
                  <div className='col-md-12 mb-3 mt-2'>
                    <div className='x-former-detail'>
                      <h3 className='mb-2'>Initial Reading *</h3>
                    </div>
                  </div>
                  <div className='col-md-4 mb-4 tc-detail-initial-reading'>
                    <label className='form-label font-regular-400'>Select Month</label>
                    <DatePicker
                      dateFormat='MMMM yyyy'
                      showMonthYearPicker
                      showFullMonthYearPicker
                      showThreeColumnMonthYearPicker
                      placeholderText='Select'
                      selected={state.initialReadingMonth}
                      onChange={(date) => setState({ ...state, initialReadingMonth: date })}
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>Reading</label>
                    <input
                      disabled={!!state.remarks}
                      value={state.initialReading}
                      onChange={(e) => {
                        handleStateChange('initialReading', e)
                      }}
                      className='form-control'
                      type='text'
                      placeholder='Reading'
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>Remarks</label>
                    <select
                      disabled={!!state.initialReading}
                      className='form-select'
                      aria-label='Remarks'
                      value={state.remarks}
                      onChange={(e) => {
                        handleStateChange('remarks', e)
                      }}
                    >
                      <option value=''>Remarks</option>
                      {tcReadingRemarks.map((item) => (
                        <option
                          value={item.value}
                          key={item.value}
                        >
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* ////////////////////////FINAL REAIDNG/////////////////////// */}
                  {/* <div className='col-md-4 mb-4' />
                  <hr className='divider' />
                  <div className='col-md-12 mb-3 mt-2'>
                    <div className='x-former-detail'>
                      <h3 className='mb-2'>Final Reading</h3>
                    </div>
                  </div>
                  <div className='col-md-4 mb-4 tc-detail-initial-reading'>
                    <label className='form-label'>Select Month</label>
                    <input
                      className='form-control'
                      disabled
                      type='text'
                      placeholder='Select'
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label'>Reading</label>
                    <input
                      className='form-control'
                      disabled
                      type='text'
                      placeholder='Reading'
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label'>Remarks</label>
                    <select
                      className='form-select'
                      aria-label='Remarks'
                      disabled
                    >
                      <option value=''>Remarks</option>
                      <option value='1'>One</option>
                      <option value='2'>Two</option>
                      <option value='3'>Three</option>
                    </select>
                  </div>
                  <div className='col-md-4 mb-4' />
                  <hr className='divider' /> */}
                  <div className='col-md-12 mt-3 text-end tc-submit-btn'>
                    <button
                      onClick={tcConfirmScreenHandler}
                      className='btn btn-primary custom-active'
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* //////////////////////////////////Confirm Screen//////////////////////////////////////////// */}
          {activeScreen === 'confirm_tc_form' && (
            <div className='transformer-detail-form p-3 mb-3'>
              <div className='row'>
                <div className='col-md-12 mb-2 mt-2'>
                  <div className='x-former-detail'>
                    <h3>Transformer Details</h3>
                  </div>
                </div>
                <div className='col-md-12 mb-3 mt-2'>
                  <div className='energy-audit-view'>
                    <ul className='ps-2'>
                      <li>
                        <p className='heading mb-0'>Select MR Code</p>
                        <p>
                          <ul>
                            {state.taggedMRs.map((item) => (
                              <li key={item.value}>{item.label}</li>
                            ))}
                          </ul>
                        </p>
                      </li>
                      <li>
                        <p className='heading mb-0'>TC Reading MR</p>
                        <p>
                          <ul>
                            {
                              state.taggedMRs.filter((item) => item.value === state.readingMR)[0]
                                ?.label
                            }
                          </ul>
                        </p>
                      </li>
                      <li>
                        <p className='heading mb-0'>Reading Day</p>
                        <p>{state.readingDay}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>TC Number</p>
                        <p>{state.tcNumber}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>TC Name</p>
                        <p>{state.tcName}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>TC Serial Number</p>
                        <p>{state.tcSerialNumber || '----'}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>TC Capacity</p>
                        <p>{state.tcCapacity || '----'}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>Tims Code</p>
                        <p>{state.timsCode || '----'}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>DTLMS</p>
                        <p>{state.dtlmsCode || '----'}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>DTR</p>
                        <p>{state.dtrCode || '----'}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>GPS Location</p>
                        <p>{`${state?.gpsCoordinates?.latitude}, ${state?.gpsCoordinates?.longitude}`}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>Execution Type</p>
                        <p>{`${state.executionType}` || '----'}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>Selected Feeder</p>
                        <p>{feederNumber}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr className='divider' />
                <div className='col-md-12 mb-2 mt-2'>
                  <div className='x-former-detail'>
                    <h3>Meter Details</h3>
                  </div>
                </div>
                <div className='col-md-12 mb-3 mt-2'>
                  <div className='energy-audit-view'>
                    <ul className='ps-2'>
                      <li>
                        <p className='heading mb-0'>Make</p>
                        <p>{state.meterMake || '----'}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>Serial Number</p>
                        <p>{state.meterSerialNumber || '----'}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>Meter Constant</p>
                        <p>{state.meterConstant || '----'}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>CT Ratio</p>
                        <p>{`${state.primaryCurrent} / ${state.secondaryCurrent}`}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr className='divider' />
                <div className='col-md-12 mb-2 mt-2'>
                  <div className='x-former-detail'>
                    <h3>Initial Reading</h3>
                  </div>
                </div>
                <div className='col-md-12 mb-3 mt-2'>
                  <div className='energy-audit-view'>
                    <ul className='ps-2'>
                      <li>
                        <p className='heading mb-0'>Selected Month</p>
                        <p>
                          {(state?.initialReadingMonth &&
                            DateToYYYYMM(state.initialReadingMonth)) ||
                            '----'}
                        </p>
                      </li>
                      <li>
                        <p className='heading mb-0'>Reading</p>
                        <p>{state.initialReading || '----'}</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>Remarks</p>
                        <p>{state.remarks || '----'}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <hr className='divider' />
                <div className='col-md-12 mb-2 mt-2'>
                  <div className='x-former-detail'>
                    <h3>Final Reading</h3>
                  </div>
                </div>
                <div className='col-md-12 mb-3 mt-2'>
                  <div className='energy-audit-view'>
                    <ul className='ps-2'>
                      <li>
                        <p className='heading mb-0'>Selected Month</p>
                        <p>----</p>
                      </li>
                      <li>
                        <p className='heading mb-0'>Reading</p>
                        <p>----</p>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <hr className='divider' />
                <div className='col-md-12'>
                  <div className='text-end tc-edit-confirm-btn'>
                    <button
                      onClick={tcFormShowHandler}
                      className='btn btn-primary edit-btn me-3'
                    >
                      Edit
                    </button>
                    <button
                      onClick={handleAddTC}
                      type='submit'
                      className='btn btn-primary confirm-btn'
                      disabled={isDisabled}
                    >
                      Confirm
                      {isDisabled && (
                        <span
                          className='spinner-grow spinner-grow-sm'
                          role='status'
                          aria-hidden='true'
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default AddTC
