import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import userIcon from '../../assets/img/fileUpload/user-onboarding.png'
import mnrIcon from '../../assets/img/fileUpload/mnr.png'
import tcCreate from '../../assets/img/fileUpload/create-tc.png'
import vacantIcon from '../../assets/img/fileUpload/vacant.png'
import doorLockIcon from '../../assets/img/fileUpload/doorlock.png'
import isrIcon from '../../assets/img/fileUpload/isr.png'
import zeroConsumptionIcon from '../../assets/img/fileUpload/zero-consumption.png'
import unbilledIcon from '../../assets/img/fileUpload/unbilled.png'
import tcTagIcon from '../../assets/img/fileUpload/tc-rr-tag.png'
import { AuditMonthContext } from '../../context/AuditMonthContext'
import { NotifierContext } from '../../context/NotifierContext'
import { fetchUserDesignation } from '../../services/utils/meta'
import { HierarchyContext } from '../../context/HierarchyContext'
import './fileUpload.scss'
import RightArrow from '../../assets/img/chevron-right.png'
import abnormalIcon from '../../assets/img/fileUpload/abnormal.png'
import subnormalIcon from '../../assets/img/fileUpload/subnormal.png'
import consumptionDcbIcon from '../../assets/img/fileUpload/consumption-dcb.png'
import psAndFeederIcon from '../../assets/img/fileUpload/ps-and-feeders.png'
import revenueDcbIcon from '../../assets/img/fileUpload/revenue-dcb.png'
import MrOnboarding from './Files/MrOnboarding/MrOnboarding'
import CreateTc from './Files/CreateTc/CreateTc'
import Vacant from './Files/Vacant/Vacant'
import VacantFileHistory from './Files/Vacant/VacantFileHistory'
import Isr from './Files/Isr/Isr'
import TcTagging from './Files/TcTagging/TcTagging'
import NoResource from './Files/NoResource'
import CreateTcFileHistory from './Files/CreateTc/CreateTcFileHistory'
import MrOnboardingFileHistory from './Files/MrOnboarding/MrOnboardingFileHistory'
import IsrFileHistory from './Files/Isr/IsrFileHistory'
import TcTaggingFileHistory from './Files/TcTagging/TcTaggingFileHistory'
import Unbilled from './Files/Unbilled/Unbilled'
import UnbilledFileHistory from './Files/Unbilled/UnbilledFileHistory'
import Mnr from './Files/Mnr/Mnr'
import MnrFileHistory from './Files/Mnr/MnrFileHistory'
import Doorlock from './Files/Doorlock/Doorlock'
import DoorlockFileHistory from './Files/Doorlock/DoorlockFileHistory'
import ZeroConsumption from './Files/ZeroConsumption/ZeroConsumption'
import ZeroConsumptionFileHistory from './Files/ZeroConsumption/ZeroConsumptionFileHistory'
import Abnormal from './Files/Abnormal/Abnormal'
import AbnormalFileHistory from './Files/Abnormal/AbnormalFileHistory'
import Subnormal from './Files/Subnormal/Subnormal'
import SubnormalFileHistory from './Files/Subnormal/SubnormalFileHistory'
import ConsumptionDcb from './Files/ConsumptionDcb/ConsumptionDcb'
import ConsumptionDcbFileHistory from './Files/ConsumptionDcb/ConsumptionDcbFileHistory'
import RevenueDcb from './Files/RevenueDcb/RevenueDcb'
import RevenueDcbFileHistory from './Files/RevenueDcb/RevenueDcbFileHistory'
import StationAndFeeders from './Files/StationAndFeeders/StationAndFeeders'
import StationAndFeedersFileHistory from './Files/StationAndFeeders/StationAndFeedersFileHistory'
import UserOnboarding from './Files/User Onboarding/UserOnboarding'
import UserOnboardingFileHistory from './Files/User Onboarding/UserOnboardingFileHistory'

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

const FileUploadList = (props) => {
  const { auditMonthYear } = useContext(AuditMonthContext)
  const notifier = useContext(NotifierContext)
  const { selectedHierarchy } = useContext(HierarchyContext)

  const loggedInUserDesignation = fetchUserDesignation()

  const categoriesArray = [
    ...(loggedInUserDesignation === 'ae'
      ? [{
          name: 'MR onboarding',
          slug: 'mrOnboarding',
          historySlug: 'mrOnboardingFileHistory',
          icon: userIcon,
          disabled: false
        }]
      : [{
          name: 'User onboarding',
          slug: 'userOnboarding',
          historySlug: 'userOnboardingFileHistory',
          icon: userIcon,
          disabled: false
        }]),
    {
      name: 'Power Stations and feeders',
      slug: 'stationAndFeeders',
      historySlug: 'stationAndFeedersFileHistory',
      icon: psAndFeederIcon,
      disabled: loggedInUserDesignation === 'ae' ? 'This feature is only for corporate office user' : false
    },
    {
      name: 'Create TC',
      slug: 'createTc',
      historySlug: 'createTcFileHistory',
      icon: tcCreate,
      disabled: false
    },
    {
      name: 'Installation status report',
      slug: 'isr',
      historySlug: 'isrFileHistory',
      icon: isrIcon,
      disabled: false
    },
    {
      name: 'TC installation tagging',
      slug: 'tcRrTag',
      historySlug: 'tcRrTaggingFileHistory',
      icon: tcTagIcon,
      disabled: false
    },
    {
      name: 'Unbilled report',
      slug: 'unbilled',
      historySlug: 'unbilledFileHistory',
      icon: unbilledIcon,
      disabled: false
    },
    {
      name: 'MNR report',
      slug: 'mnr',
      historySlug: 'mnrFileHistory',
      icon: mnrIcon,
      disabled: false

    },
    {
      name: 'Vacant report',
      slug: 'vacant',
      historySlug: 'vacantFileHistory',
      icon: vacantIcon,
      disabled: false

    },
    {
      name: 'Doorlock report',
      slug: 'doorlock',
      historySlug: 'doorlockFileHistory',
      icon: doorLockIcon,
      disabled: false

    },
    {
      name: 'Zero consumption report',
      slug: 'zeroConsumption',
      historySlug: 'zeroConsumptionFileHistory',
      icon: zeroConsumptionIcon,
      disabled: false

    }, {
      name: 'Abnormal consumption report',
      slug: 'abnormal',
      historySlug: 'abnormalFileHistory',
      icon: abnormalIcon,
      disabled: false

    }, {
      name: 'Subnormal consumption report',
      slug: 'subnormal',
      historySlug: 'subnormalFileHistory',
      icon: subnormalIcon,
      disabled: false

    },
    {
      name: 'Consumption DCB report',
      slug: 'consumptionDcb',
      historySlug: 'consumptionDcbFileHistory',
      icon: consumptionDcbIcon,
      disabled: false

    },
    {
      name: 'Revenue DCB report',
      slug: 'revenueDcb',
      historySlug: 'revenueDcbFileHistory',
      icon: revenueDcbIcon,
      disabled: false

    }
  ]
  const [categories] = useState(categoriesArray)

  // const [loading, setLoading] = useState(true);

  useEffect(() => {
  }, [auditMonthYear, selectedHierarchy])

  const components = {
    noResource: NoResource,
    ...(loggedInUserDesignation === 'ae'
      ? { mrOnboarding: MrOnboarding }
      : { userOnboarding: UserOnboarding }),
    ...(loggedInUserDesignation === 'ae'
      ? { mrOnboardingFileHistory: MrOnboardingFileHistory }
      : { userOnboardingFileHistory: UserOnboardingFileHistory }),
    stationAndFeeders: StationAndFeeders,
    stationAndFeedersFileHistory: StationAndFeedersFileHistory,
    createTc: CreateTc,
    createTcFileHistory: CreateTcFileHistory,
    isr: Isr,
    isrFileHistory: IsrFileHistory,
    tcRrTag: TcTagging,
    tcRrTaggingFileHistory: TcTaggingFileHistory,
    vacant: Vacant,
    vacantFileHistory: VacantFileHistory,
    unbilled: Unbilled,
    unbilledFileHistory: UnbilledFileHistory,
    zeroConsumption: ZeroConsumption,
    zeroConsumptionFileHistory: ZeroConsumptionFileHistory,
    mnr: Mnr,
    mnrFileHistory: MnrFileHistory,
    doorlock: Doorlock,
    doorlockFileHistory: DoorlockFileHistory,
    abnormal: Abnormal,
    abnormalFileHistory: AbnormalFileHistory,
    subnormal: Subnormal,
    subnormalFileHistory: SubnormalFileHistory,
    consumptionDcb: ConsumptionDcb,
    consumptionDcbFileHistory: ConsumptionDcbFileHistory,
    revenueDcb: RevenueDcb,
    revenueDcbFileHistory: RevenueDcbFileHistory

  }

  const query = useQuery()
  const history = useHistory()

  const slug = query.get('tab') || 'mrOnboarding'

  // const ComponentName = components[query.get('tab')] ?? components.noResource

  const ComponentName = (function () {
    try {
      const activeComponent = components[query.get('tab')]

      if (activeComponent === null || activeComponent === undefined) {
        return components.noResource
      }

      // const isComponentDisabled = categoriesArray.find((category) => category.slug === query.get('tab')).disabled
      // if (isComponentDisabled) {
      //   return components.noResource
      // }

      return activeComponent
    } catch (err) {
      notifier.notifyError(err)
    }
  }())

  const redirectHandler = (slug) => {
    history.push('/file-upload?tab=' + slug)
  }

  return (
    <>
      <div className='file-upload-content'>
        <div style={{ maxWidth: '100%' }}>
          <div className='row g-0'>
            <div className='sidebar-container'>
              <div className='tc-audit-no-list'>
                {categories.map((category, index) => {
                  return (
                    <div
                      disabled={!!category.disabled}
                      data-bs-toggle='tooltip' data-bs-placement='top' title={category.disabled}
                      key={index}
                    >
                      <div
                        onClick={() => redirectHandler(category.slug)}
                        className={`tc-audit-no-collapse ${
                          (slug === category.slug || slug === category.historySlug) ? 'active' : ''
                        } pt-3 pb-3 ps-2 pe-2`}
                      >
                        <card className='collapse-text d-flex align-items-center'>
                          <span className='iconbox'>
                            <img
                              className='img-fluid'
                              alt='missing icon'
                              src={category.icon}
                            />
                          </span>
                          <div className='mb-0 tc-audit-no-title ms-2'>
                            <p className='mb-1 tc-no'>{category.name}</p>
                          </div>
                          <img
                            className='img-fluid m-auto right-arrow'
                            src={RightArrow}
                          />
                        </card>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='file-upload-container'>
              <ComponentName />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FileUploadList
