import React, { useState } from 'react'
import Modal from '../atoms/Modal'
import editIcon from '../../assets/img/view-selected-icon@2x.png'
import hidePwd from '../../assets/img/eye-hide.png'

const ChangePwd = ({
  handlePwdChange,
  loading,
  modalVisible,
  setModalVisibility
}) => {
  const [newPwd, setNewPwd] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')

  const [error, setError] = useState('')

  const onSubmit = () => {
    try {
      if (newPwd.length === 0) {
        throw new Error('Please Enter new password')
      }
      if (
        !newPwd.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#@$%&'*+/=?^`{|}~-])[A-Za-z\d!#@$%&'*+/=?^`{|}~-]{8,}$/
        )
      ) {
        throw new Error(
          'Follow the instructions given below and enter a valid password.'
          // 'New Password should at least 8 characters long with at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 digit.'
        )
      }
      if (confirmPassword === '') {
        throw new Error('Confirm your new password')
      }
      if (newPwd !== confirmPassword) {
        throw new Error('New password and confirm password must match')
      }
      handlePwdChange(newPwd)
    } catch (err) {
      setError(err)
    }
  }

  return (
    <Modal
      okAction={onSubmit}
      okBtnDisabled={loading || !newPwd}
      okBtnLoading={loading}
      visible={modalVisible}
      onModalClose={() => setModalVisibility(false)}
      title='Change your password'
    >
      <div
        className='login-form-detail'
        style={{ padding: 10, margin: 0 }}
      >
        <div className='mb-3 forms-input'>
          <i
            className='fa fa-lock'
            aria-hidden='true'
          />
          <label
            className='mb-2'
            style={{ fontSize: 15 }}
          >
            Enter New Password
          </label>
          <input
            onChange={(e) => { setError(''); setNewPwd(e.target.value) }}
            type={showPassword ? 'text' : 'password'}
            value={newPwd}
            placeholder='Password'
            name='password'
            className='form-control'
          />
          <img
            src={editIcon}
            style={{
              width: 22,
              height: 22,
              position: 'absolute',
              right: 18,
              bottom: 15,
              cursor: 'pointer'
            }}
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>

        <div className='mb-1 forms-input'>
          <i
            className='fa fa-lock'
            aria-hidden='true'
          />
          <label
            className='mb-2'
            style={{ fontSize: 15 }}
          >
            Confirm Password
          </label>
          <input
            onChange={(e) => { setError(''); setConfirmPassword(e.target.value) }}
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            placeholder='Password'
            name='password'
            className='form-control'
          />
          <img
            src={showConfirmPassword ? editIcon : hidePwd}
            style={{
              width: 22,
              height: 22,
              position: 'absolute',
              right: 18,
              bottom: 15,
              cursor: 'pointer'
            }}
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          />
        </div>
        <label
          className='mb-4'
          style={{ fontSize: 12, marginLeft: 5, color: 'red' }}
        >{error.message}
        </label>
        <label
          className='mb-1'
          style={{ fontSize: 14, marginLeft: 5 }}
        >
          Min. length 8 chars with at least:
          <br />1 number,
          <br /> 1 uppercase,
          <br /> 1 lowercase,
          <br /> 1 special char
        </label>
      </div>
    </Modal>
  )
}

export default ChangePwd
