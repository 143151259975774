import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import close from '../../assets/img/close-icon.png'
import { NotifierContext } from '../../context/NotifierContext'
import { updateMR } from '../../services/api/mrService'
import { updateMRValidation } from '../../services/validators/mrManagement'

const EditMR = () => {
  const history = useHistory()
  const location = useLocation()
  const notifier = useContext(NotifierContext)

  useEffect(() => {
    let timer
    // Check if the state data is available
    if (!location.state) {
      timer = setTimeout(() => history.replace('/mr-management'), 2000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [location, history])

  const [formFields, setFormFields] = useState({
    firstName: location.state?.firstName ?? '',
    lastName: location.state?.lastName ?? '',
    code: location.state?.mrCode ?? '',
    phone: location.state?.phoneNumber ?? ''
  })
  const [isDisabled, setDisability] = useState(false)

  const handleUpdateMR = async (event) => {
    event.preventDefault()
    try {
      setDisability(true)
      const validatedData = updateMRValidation(formFields, location.state)
      await updateMR(location?.state?.id, validatedData)
      notifier.notifySuccess('Successfully updated MR')
      history.goBack()
    } catch (err) {
      notifier.notifyError(err)
    } finally {
      setDisability(false)
    }
  }

  const handleChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject)
  }

  const data = location.state

  if (data === undefined || data === null) {
    return (
      <p style={{ position: 'absolute', top: '50%', left: '50%' }}>
        No data received. Redirecting to previous page...
      </p>
    )
  }

  return (
    <>
      <div className='add-mr-content'>
        <div className='container pt-3'>
          <div className='row'>
            <div className='col-md-12 mb-3'>
              <div className='code-section'>
                <span>MR Management&nbsp; |</span>
                <span className='add-new-mr-text'>
                  <a
                    // href='#'
                    style={{ textTransform: 'capitalize' }}
                  >
                    &nbsp; Edit MR
                  </a>
                </span>
              </div>
              <hr className='horizontal-line' />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              <div className='management-title'>
                <h1 className='mb-0'>Edit MR</h1>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='close-mr'>
                <div
                  onClick={() => history.goBack()}
                  className='btn btn-primary bg-transparent'
                >
                  <img src={close} />
                  <p className='mb-0'>Close</p>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-md-12'>
              <div className='main-management-form p-3 mb-3'>
                <form>
                  <div className='forms-input'>
                    <div className='inner-add-part'>
                      <div className='row'>
                        <div className='col-md-3'>
                          <label className='form-label font-regular-400'>First name</label>
                          <input
                            name='firstName'
                            maxLength={30}
                            onChange={handleChange}
                            value={formFields.firstName}
                            className='form-control'
                            type='text'
                            placeholder='Enter First name'
                          />
                        </div>
                        <div className='col-md-3'>
                          <label className='form-label font-regular-400'>Last name</label>
                          <input
                            name='lastName'
                            maxLength={30}
                            onChange={handleChange}
                            value={formFields.lastName}
                            className='form-control'
                            type='text'
                            placeholder='Enter Last name'
                          />
                        </div>
                        <div className='col-md-3'>
                          <label className='form-label font-regular-400'>MR Code</label>
                          <input
                            name='code'
                            value={formFields.code}
                            onChange={handleChange}
                            className='form-control'
                            type='text'
                            maxLength={15}
                            placeholder='MR Code'
                            disabled
                          />
                        </div>
                        <div className='col-md-3'>
                          <label className='form-label font-regular-400'>Phone Number</label>
                          <input
                            maxLength={10}
                            minLength={10}
                            name='phone'
                            onChange={handleChange}
                            value={formFields.phone}
                            className='form-control'
                            type='text'
                            placeholder='Phone Number'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12 mt-1 text-end mr-submit-btn'>
                        <button
                          type='submit'
                          disabled={
                            isDisabled
                              ? true
                              : !(formFields.firstName && formFields.phone && formFields.code)
                          }
                          onClick={handleUpdateMR}
                          className={`btn btn-primary ${!isDisabled ? '' : 'btn-grey'}`}
                        >
                          Submit{'\t'}
                          {isDisabled && (
                            <span
                              className='spinner-grow spinner-grow-sm'
                              role='status'
                              aria-hidden='true'
                            />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditMR
